<!--
 * @Description  : 
 * @Author       : BigBigger
 * @Date         : 2021-11-12 09:46:59
 * @LastEditTime : 2022-09-20 11:22:16
 * @LastEditors  : BigBigger
-->
<!--订单详情-->
<template>
  <div class="app-page-2">
    <div class="container">
      <Banner></Banner>
      <div class="mineContainer">
        <LeftNav></LeftNav>

        <div class="protocolContain" v-if="orderInfo">
          <div class="title">
            <h3>订单详情</h3>
          </div>
          <!-- 个人信息 -->
          <div class="part" v-if="orderInfo.personalInfo">
            <div class="p-title">个人信息
              <el-button size="mini" type="primary" style="margin-left: 30px;padding: 5px 10px;" v-if="orderInfo.payInfo.confirmStatus == 1" @click="updateUserInfo">修改个人信息</el-button>
            </div>
            <el-row>
              <el-col :span="12">
                <p class="p-item">姓名：{{orderInfo.personalInfo.realName}}</p>
              </el-col>
              <el-col :span="12">
                <p class="p-item">性别：{{orderInfo.personalInfo.gender | sexFilter}}</p>
              </el-col>
              <el-col :span="12">
                <p class="p-item">电话：{{orderInfo.personalInfo.phone}}</p>
              </el-col>
              <template v-if="orderInfo.type === 1">
                <el-col :span="12">
                  <p class="p-item">新/老学员：{{orderInfo.personalInfo.newStudent | studentFilter}}</p>
                </el-col>
                <el-col :span="12">
                  <p class="p-item">笔试总分：{{orderInfo.personalInfo.totalScore}}</p>
                </el-col>
                <el-col :span="12">
                  <p class="p-item">笔试排名：{{orderInfo.personalInfo.ranking}}</p>
                </el-col>
                <el-col :span="12">
                  <p class="p-item">专业：{{orderInfo.personalInfo.profession}}</p>
                </el-col>
                <el-col :span="24">
                  <p class="p-item">地址：{{orderInfo.personalInfo.address}}</p>
                </el-col>
              </template>
              <el-col :span="12">
                <p class="p-item">应届/往届：{{orderInfo.personalInfo.freshStudent | freshStudentFilter}}</p>
              </el-col>
              <el-col :span="12">
                <p class="p-item">学校：{{orderInfo.personalInfo.school}}</p>
              </el-col>
              <!-- 暂时取消备注区别 -->
              <!-- <el-col :span="24" v-if="isZJ"> -->
              <el-col :span="24">
                <p class="p-item">备注：{{orderInfo.personalInfo.remark}}</p>
              </el-col>
              <!-- <el-col :span="24" v-else>
                <p class="p-item">查分密码：{{orderInfo.personalInfo.remark}}</p>
              </el-col> -->
            </el-row>
          </div>
          <div class="part" v-if="orderInfo.courseInfo">
            <div class="p-title">课程信息</div>
            <el-row>
              <el-col :span="24">
                <p class="p-item">订单编号：{{orderInfo.courseInfo.orderCode}}</p>
              </el-col>
              <el-col :span="24">
                <p class="p-item">课程名称：{{orderInfo.courseInfo.courseGroupName}}</p>
              </el-col>
              <el-col :span="24" v-if="orderInfo.courseInfo.whetheHaveClassManager === 1">
                <!-- <p class="p-item">班级信息：{{orderInfo.courseInfo.courseGroupName}}</p> -->
                <div class="p-item class-info">
                  <span class="info">班级信息：</span>
                  <p class="class-list">
                    <span v-for="(item,index) in flightNameList" :key="index">{{item}}</span>
                  </p>
                </div>
              </el-col>
              <el-col :span="12" v-if="orderInfo.courseInfo.whetheHaveClassManager !== 1">
                <p class="p-item">上课时间：{{orderInfo.courseInfo.classTime}}</p>
              </el-col>
              <el-col :span="12">
                <p class="p-item">上课地点：{{orderInfo.courseInfo.classLocation}}</p>
              </el-col>
              <el-col :span="12">
                <p class="p-item">支付类型：{{orderInfo.courseInfo.payWay}}</p>
              </el-col>
              <el-col :span="12">
                <p class="p-item">课程费用：<span class="text-red">￥{{orderInfo.courseInfo.throughPay === 1 && orderInfo.throughPay === 1 ? orderInfo.courseInfo.showPrice : orderInfo.courseInfo.payMoney}}</span></p>
              </el-col>
              <el-col :span="12">
                <p class="p-item">费用说明：{{orderInfo.courseInfo.remark}}</p>
              </el-col>
              <el-col :span="12">
                <p class="p-item">下单时间：{{orderInfo.courseInfo.createTime}}</p>
              </el-col>
              
            </el-row>
          </div>
          <!-- 职位信息 -->
          <div class="part" v-if="orderInfo.positionInfo && orderInfo.type === 1">
            <div class="p-title">职位信息</div>
            <el-row>
              <el-col :span="24">
                <p class="p-item">职位名称：{{orderInfo.positionInfo.positionName}}</p>
              </el-col>
              <el-col :span="24">
                <p class="p-item">部门名称：{{orderInfo.positionInfo.departmentName}}</p>
              </el-col>
              <el-col :span="24">
                <p class="p-item">单位名称：{{orderInfo.positionInfo.employer}}</p>
              </el-col>
              <el-col :span="24">
                <p class="p-item">职位代码：{{orderInfo.positionInfo.jobCode}}</p>
              </el-col>
              <el-col :span="24">
                <p class="p-item">招考人数：{{orderInfo.positionInfo.numberCandidates}}</p>
              </el-col>
            </el-row>
          </div>
          <!-- 优惠信息 -->
          <div class="part" v-if="orderInfo && orderInfo.personalInfo.studentsPreferential === 1">
            <div class="p-title">优惠信息</div>
            <el-row>
              <el-col :span="12">
                <p class="p-item">是否老学员：{{orderInfo.personalInfo.studentsPreferential === 1 ? '是(参与老学员优惠)' : '否'}}</p>
              </el-col>
              <el-col :span="12">
                <p class="p-item"><span style="vertical-align: top;">老学员证明：</span><el-image :src="orderInfo.personalInfo.field01" style="width: 100px; height: 100px" fit="contain" alt="" /></p>
              </el-col>
            </el-row>
          </div>
          <!-- 支付信息 -->
          <div class="part" v-if="orderInfo.payInfo">
            <div class="p-title">支付信息</div>
            <el-row>
              <el-col :span="12">
                <p class="p-item">下单时间：{{orderInfo.payInfo.createTime}}</p>
              </el-col>
              <el-col :span="12">
                <p class="p-item">订单状态：{{orderInfo.payInfo.orderStatus | orderStateFilter}}</p>
              </el-col>
              <template v-if="orderInfo.type === 1">
                <el-col :span="12">
                  <p class="p-item">审核时间：{{orderInfo.payInfo.confirmTime}}</p>
                </el-col>
                <el-col :span="12">
                  <p class="p-item">审核状态：{{orderInfo.payInfo.confirmStatus | confirmStateFilter}}</p>
                </el-col>
              </template>
              <el-col :span="12">
                <p class="p-item text-red">课程费用：<span class="text-red">￥{{(orderInfo.courseInfo.throughPay === 1 && orderInfo.throughPay === 1) ? orderInfo.courseInfo.showPrice : orderInfo.courseInfo.payMoney}}</span></p>
              </el-col>
              <el-col :span="12">
                <p class="p-item" v-if="orderInfo.courseInfo.throughPay === 1">缴费方式：{{ orderInfo.throughPay | throughPayFilter }}</p>
              </el-col>
              <el-col :span="12">
                <p class="p-item">付款方式：{{orderInfo.payWay | paywayFilter}}</p>
              </el-col>
              <!-- <el-col :span="12">
                <p class="p-item text-red">课程余款：{{orderInfo.payInfo.balance}}</p>
              </el-col> -->
              <el-col :span="12">
                <p class="p-item">支付金额：<span class="text-red">￥{{+orderInfo.payWay === 1 ? getPrice : orderInfo.courseInfo.deposit}}</span></p>
              </el-col>
              <el-col :span="12">
                <p class="p-item">支付状态：<span class="text-red">{{orderInfo.payInfo.payStatus | payStateFilter}}</span></p>
              </el-col>
            </el-row>
          </div>
          <!-- 立即支付 -->
          <div class="pay-now" v-if="showPay">
            <div class="p-right" v-if="orderInfo">
              <span>实付金额：</span>
              <!-- 定金支付 -->
              <span class="text-red">￥{{+orderInfo.payWay === 1 ? getPrice : orderInfo.courseInfo.deposit}}</span>
              <button class="pay-btn" @click="toPay(id)">立即支付</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <updateUserInfo ref="updateUserInfo" @reload="getOrderDetail"></updateUserInfo>
  </div>
</template>
<script>
import Banner from '@/components/mine/banner'
import LeftNav from '@/components/mine/leftNav'
import updateUserInfo from '@/components/mine/updateUserInfo'
import { getOrderDetail, getFlightName} from '@/api/api'
import { SEX, STUDENT, FRESHSTUDENT, ORDERSTATE, CONFIRMSTATE, PAYSTATE, PAYWAY, THROUGH_PAY } from "@/config/config"

export default {
  name:'course',
  components:{
    Banner,
    LeftNav,
    updateUserInfo
  },
  data(){
    return{
      id: null,
      orderInfo: null,
      page: 1,
      limit: 1000,
      isZJ: process.env.VUE_APP_AREA === 'zj' ? true : false,
      flightIds: [],
      flightNameList: []
    }
  },
  mounted(){
    this.id = this.$route.query.id;
    this.getOrderDetail()
  },
  filters: {
    sexFilter(val) {
      return SEX[+val] || ''
    },
    studentFilter(val) {
      return STUDENT[+val] || ''
    },
    freshStudentFilter(val) {
      return FRESHSTUDENT[+val] || ''
    },
    orderStateFilter(val) {
      return ORDERSTATE[+val] || ''
    },
    confirmStateFilter(val) {
      return CONFIRMSTATE[+val] || ''
    },
    payStateFilter(val) {
      return PAYSTATE[+val] || ''
    },
    paywayFilter(val) {
      return PAYWAY[+val]
    },
    throughPayFilter(val) {
      return THROUGH_PAY[+val] || ''
    }
  },
  computed: {
    showPay() {
      let state = false;
      if(this.orderInfo.payInfo) {
        // 如果是关联订单
        if(+this.orderInfo.type === 1) {
          if(+this.orderInfo.payInfo.orderStatus === 1 && +this.orderInfo.payInfo.confirmStatus === 2) {
            state = true
          } else {
            state = false
          }
        } else {
          // 如果未支付
          if(+this.orderInfo.payInfo.orderStatus === 1) {
            state = true
          } else {
            state = false
          }
        }
      } else {
        state = false
      }
      return state
    },
    getPrice() {
      if (!this.orderInfo.courseInfo || !this.orderInfo.personalInfo) return '';
      if (
        this.orderInfo.personalInfo.studentsPreferential === 1
        && this.orderInfo.personalInfo.field01
      ) {
        return this.orderInfo.personalInfo.afterOldStudentsPrice;
      }
      return this.orderInfo.courseInfo.payMoney;
    },
  },
  methods:{
    toPay(id){
      this.$router.push({path: `/course/signup?step=3&orderId=${id}`})
    },
    async getOrderDetail() {
      let response = await getOrderDetail({orderId: this.id})
      if(+response.returnCode !== 10001) {
        return false
      }
      this.orderInfo = response.data
      let str = this.orderInfo.courseInfo.flightNames
      let strs = str.split(",");
      this.flightNameList = strs
    },
    updateUserInfo() {
      this.$refs.updateUserInfo.show({
        orderId: this.id,
        totalScore: this.orderInfo.personalInfo.totalScore,
        ranking: this.orderInfo.personalInfo.ranking,
        realName: this.orderInfo.personalInfo.realName,
        phone: this.orderInfo.personalInfo.phone
      });
    },
  },
}
</script>
<style lang="less" scoped>
.container{
  padding-top:0px;
}
.mineContainer{
  width:1200px;
  overflow: hidden;
  background:#fff;
  margin:0 auto 30px auto;
  border: 1px solid #DBDBDB;
  display: flex;
}
.protocolContain{
  width:985px;
  padding:0 30px;
  overflow: hidden;
  background:#fff;
  border-left:1px solid #E8E8E8;
  min-height: 375px;
  float:left;
  .title{
    margin-bottom: 20px;
  }
  .title h3{
    font-size: 22px;
    color:#333333;
    text-align: left;
    font-weight: normal;
    line-height: 75px;
    height: 75px;
  } 
}
.part{
  margin-bottom: 20px;
  .class-info{
    display: flex;
    .info{
      flex-grow: 0;
      flex-shrink: 0;
    }
    .class-list span{
      margin-right: 10px;
    }
  }
  .p-title{
    height: 18px;
    line-height: 18px;
    font-weight: bold;
    border-left: 3px solid #FF3F47;
    padding-left: 10px;
    margin-bottom: 20px;
  }
  .p-item{
    word-break: break-all;
    color: #333333;
    margin: 10px 0;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 14px;
  }
}
.text-red{
  color: #FF3F47;
}
.pay-now{
  border-top: 1px solid #e8e8e8;
  padding-top: 40px;
  .p-right{
    float: right;
    font-size: 16px;
    color: #333;
  }
  .pay-btn{
    width: 148px;
    height: 40px;
    background: #FF3F47;
    border-radius: 6px;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
    color: #fff;
    margin-left: 30px;
  }
}
</style>
